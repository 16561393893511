/* General Styles */

* {
  overflow: hidden;
  overflow-y: scroll;
}


/* Home Hero Section Styles */

.home-hero-container {
  background-color: #222020;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* a subtle shadow for depth */ 
  padding: 8rem 2rem;
  width: 100%;
  height: 100%;
  margin-top: 4rem;
}

.hero-card{
  
}

.hero-card-content{
  background-color: #eff3f7;
  width: 68%;
  border-radius: 15px;
  padding: 2rem 1.5rem;
  margin-bottom: 2rem;
  justify-items: center;
  margin: 0 auto;
}

.hero-p {
  font-size: 1.2rem;
  color:  #303133;
  line-height: 1.6;
  text-align: center;
  margin: 0rem 3rem ;
}

.hero-h1 {
  text-align: center;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.3;
  margin: 0.5rem 0;
  color: #111;
}

.hero-h1 .highlight {
  color: #0073e6;
}

.hero-button,
.hero-white-btn {
  padding: 0.7rem 1.5rem;            /* Unified padding */
  font-size: 1.1rem;
  border-radius: 8px;
  font-weight: 600;
  border: none;
  text-decoration: none;
  cursor: pointer;
  margin: 1.5rem 0.5rem ;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;                       /* Space between emoji and text */
}

.hero-button {
  background-color: #0073e6;
  color: white;
}

.hero-button:hover {
  background-color: #005bb5;
}

.hero-white-btn {
  background-color: #d5e4ec;
  color: black;
}

.hero-white-btn:hover {
  background-color: #c2d7e4;
}


.hero-button-text {
  padding: 0.6rem 0.8rem;
}


.what-i-focus-on-container{
  background-color: #eff3f7 ;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* a subtle shadow for depth */ 
  text-align: center;
  padding: 2rem 1.1rem;
  text-align: center;
  padding: 1rem 3.3rem;
  width: 100%;
  height: 100%;
}

.my-focus {
  display: flex;
  font-size: 3rem;
  font-weight: bold;
  color: #454648;
  justify-self: center;
  padding: 1rem 0rem;
}

.my-focus-content{
  color: #414344;
  width: 68%;
  font-size: 1.3rem;
  letter-spacing: 0.3px;
  border-radius: 15px;
  margin-bottom: 2rem;
  justify-items: center;
  padding-top: -4rem;
  margin: 0 auto;
}
.my-expertise {
  display: flex;
  font-size: x-large;
  font-weight: bold;
  justify-self: center;
  padding: 2rem 0rem;
}

.my-core-competencies{
  color: #414344;
  width: 68%;
  font-size: 2.3rem;
  font-weight: 650;
  letter-spacing: 0.3px;
  border-radius: 15px;
  justify-items: center;
  padding-top: 4rem;
  margin: 0 auto;
}




.discovery-section{
  background-color: #d01818;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* a subtle shadow for depth */ 
  text-align: center;
  padding: 2rem 1.1rem;
  padding: 2rem 2rem;
  width: 100%;
  height: 100vh;
}






/* Mobile Responsive Styles */
@media (max-width: 767px) {
  .home-hero-container {
    background-color: #222020;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* a subtle shadow for depth */ 
    padding: 5rem 2rem;
    width: 100%;
    height: 100%;
    margin-top: 4rem;
    
  }
  
  .hero-card{
    
  }
  
  .hero-card-content{
    background-color: #eff3f7;
    width: 98%;
    border-radius: 15px;
    padding: 1rem 1rem;
    margin-bottom: 2rem;
    justify-items: center;
    margin: 0 auto;
  }
  
  .hero-par {
    font-size: 1rem;
    color:  red;
    line-height: 1.6;
    text-align: center;
  }
  .hero-h1 {
    text-align: center;
    padding: 0.5rem 2rem;
    width: 100%;
    text-align: center;
  }
  
  .discovery-section{
    background-color: #d01818;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* a subtle shadow for depth */ 
    text-align: center;
    padding: 2rem 1.1rem;
    text-align: center;
    padding: 2rem 2rem;
    width: 100%;
    height: 100vh;
    display: inline-block;
  }
  
  .what-i-focus-on-section{
    background-color: #abd018;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* a subtle shadow for depth */ 
    text-align: center;
    padding: 2rem 1.1rem;
    text-align: center;
    padding: 2rem 2rem;
    width: 100%;
    height: 100vh;
    display: inline-block;
  }
  

}

.my-focus {
  display: flex;
  font-size: xx-large;
  font-weight: bold;
  justify-self: center;
  padding: 2rem 0rem;
}
.my-expertise {
  display: flex;
  font-size: x-large;
  font-weight: bold;
  justify-self: center;
  padding: 2rem 0rem;
}




/* Media Queries for Ipad or Tablet */
@media (max-width: 1024px) {
  .home-hero-container {
    background-color: #222020;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* a subtle shadow for depth */ 
    padding: 5rem 0.5rem;
    width: 100%;
    height: 100%;
    margin-top: 4rem;
  }
  
  .hero-card{
    
  }
  
  .hero-card-content{
    background-color: #eff3f7;
    width: 85%;
    border-radius: 15px;
    padding: 2rem 1.5rem;
    margin-bottom: 2rem;
    justify-items: center;
    margin: 0 auto;
  }
  
  .hero-p {
    font-size: 1.2rem;
    color:  #303133;
    line-height: 1.5;
    text-align: center;
  }
  
  .hero-h1 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.2;
    margin: 0.6rem 0;
    color: #111;
  }
  
  .hero-h1 .highlight {
    color: #0073e6;
  }
  
  .discovery-section{
    background-color: #d01818;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* a subtle shadow for depth */ 
    text-align: center;
    padding: 2rem 1.1rem;
    padding: 2rem 2rem;
    width: 100%;
    height: 100vh;
  }
  
  .what-i-focus-on-section{
    background-color: #abd018;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* a subtle shadow for depth */ 
    text-align: center;
    padding: 2rem 1.1rem;
    text-align: center;
    padding: 2rem 2rem;
    width: 100%;
    height: 100vh;
  }
}  

.my-focus {
  display: flex;
  font-size: xx-large;
  font-weight: bold;
  justify-self: center;
  padding: 2rem 0rem;
}
.my-expertise {
  display: flex;
  font-size: x-large;
  font-weight: bold;
  justify-self: center;
  padding: 2rem 0rem;
}